import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import History from "@/views/property/History.vue";
import api from "@/api";

jest.mock("@/api");

const defaultMocks = {
  $route: { params: { uuid: "1" } },
};

const component = (data = {}, mocks = defaultMocks) => {
  return myRentalsShallowMount(History, {
    data,
    mocks,
    router: true,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("History", () => {
  testMyRentalsShallowMount(History, {
    mocks: defaultMocks,
    router: true,
  });

  describe("Mounted", () => {
    it("Should call api to retrieve property info if not in store", async () => {
      // given
      spyOn(api.property(), "retrievePropertyInfo");
      spyOn(api.property(), "retrievePropertyHistory");
      await component();
      // when
      // then
      expect(api.property().retrievePropertyInfo).toHaveBeenCalled();
      expect(api.property().retrievePropertyHistory).toHaveBeenCalled();
    });
  });
});
